import { Injectable } from '@angular/core';
import { Email, IEmail } from './email.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';

type EntityResponseType =  HttpResponse<IEmail>;

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  public resourceUrl = 'http://wenton.co.uk/mfh/public/' + 'send-email';

  constructor(protected http: HttpClient) { }

  sendEmail(message: IEmail): Observable<EntityResponseType> {
    return this.http.post<IEmail>(this.resourceUrl, message, {observe: 'response'});
  }
}
