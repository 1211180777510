import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-household-header',
  templateUrl: './household-header.component.html',
  styleUrls: ['./household-header.component.css']
})
export class HouseholdHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
