import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-diysection',
  templateUrl: './diysection.component.html',
  styleUrls: ['./diysection.component.css']
})
export class DiysectionComponent implements OnInit {

  constructor(meta: Meta, title: Title) {
    title.setTitle('Professional | MFH Underfloor Heating');

    meta.addTags([
      { name: 'author', content: 'Midlands Floor Heating' },
      { name: 'description', content: 'Bespoke underfloor heating for all of your projects.' }
    ])
  }

  ngOnInit() {
  }

}
