import { Component, OnInit } from '@angular/core';
import {Lightbox} from 'ngx-lightbox';

@Component({
  selector: 'app-household-portfolio',
  templateUrl: './household-portfolio.component.html',
  styleUrls: ['./household-portfolio.component.css']
})
export class HouseholdPortfolioComponent implements OnInit {
  public _albums: Array<{src: string, caption: string, thumb: string}> = [];

  constructor(private _lightbox: Lightbox) {
    for (let i = 1; i <= 9; i++) {
      const src = './assets/img/portfolio/0' + i + '-large.jpg';
      const caption = 'Work In Progress';
      const thumb = './assets/img/portfolio/0' + i + '-small.jpg';
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._albums.push(album);
    }
  }

  ngOnInit() {
  }

  open(index: number) {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close() {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
