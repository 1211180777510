import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-floor-sections',
  templateUrl: './floor-sections.component.html',
  styleUrls: ['./floor-sections.component.css']
})
export class FloorSectionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
