import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-builder-about',
  templateUrl: './builder-about.component.html',
  styleUrls: ['./builder-about.component.css'],
  providers: [NgbCarouselConfig]
})
export class BuilderAboutComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = true;
  public images: Array<{img: string, captionTitle: string, captionContent}> = [];

  constructor(config: NgbCarouselConfig) {
    const img1 = {
      img: './assets/img/about/mfh-about1.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img2 = {
      img: './assets/img/about/mfh-about4.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img3 = {
      img: './assets/img/about/mfh-about5.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img4 = {
      img: './assets/img/about/mfh-about6.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img5 = {
      img: './assets/img/about/mfh-about7.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img6= {
      img: './assets/img/about/mfh-about3.jpg',
      captionTitle: '',
      captionContent: ''
    }

    this.images.push(img5);
    this.images.push(img6);
    this.images.push(img2);
    this.images.push(img3);
    this.images.push(img4);
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit() {
  }

}
