import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-household-about',
  templateUrl: './household-about.component.html',
  styleUrls: ['./household-about.component.css'],
  providers: [NgbCarouselConfig]
})
export class HouseholdAboutComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = true;
  public images: Array<{img: string, captionTitle: string, captionContent}> = [];
  constructor(config: NgbCarouselConfig) {
    // add images to array
    const img1 = {
      img: './assets/img/about/mfh-about1.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img2 = {
      img: './assets/img/about/mfh-about2.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img3 = {
      img: './assets/img/about/mfh-about3.jpg',
      captionTitle: '',
      captionContent: ''
    }

    const img4 = {
      img: './assets/img/about/mfh-about7.jpg',
      captionTitle: '',
      captionContent: ''
    }

    this.images.push(img4);
    this.images.push(img1);
    this.images.push(img2);
    this.images.push(img3);
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit() {
  }

}
