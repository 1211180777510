export interface IEmail {
  name: string,
  emailAddress: string,
  message: string
}
export class Email implements IEmail {
  constructor(
    public name: string,
    public emailAddress: string,
    public message: string
  ) {}
}
