import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './navigation/navigation.component';
import { HouseholdHeaderComponent } from './household/household-header/household-header.component';
import { HouseholdGetintouchComponent } from './household/household-getintouch/household-getintouch.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { HouseholdAboutComponent } from './household/household-about/household-about.component';
import { HouseholdServicesComponent } from './household/household-services/household-services.component';
import { HouseholdPortfolioComponent } from './household/household-portfolio/household-portfolio.component';
import { LightboxModule } from 'ngx-lightbox';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ContactusComponent } from './contactus/contactus.component';
import { DiysectionComponent } from './diysection/diysection.component';
import { HousebuildersectionComponent } from './housebuildersection/housebuildersection.component';
import { BuilderAboutComponent } from './builder/builder-about/builder-about.component';
import { BuilderGetintouchComponent } from './builder/builder-getintouch/builder-getintouch.component';
import { BuilderHeaderComponent } from './builder/builder-header/builder-header.component';
import { BuilderContactusComponent } from './builder/builder-contactus/builder-contactus.component';
import { BuilderPortfolioComponent } from './builder/builder-portfolio/builder-portfolio.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { FaqBuilderComponent } from './builder/faq-builder/faq-builder.component';
import { FaqHouseholdComponent } from './household/faq-household/faq-household.component';
import { FloorSectionsComponent } from './floor-sections/floor-sections.component';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HouseholdHeaderComponent,
    HouseholdGetintouchComponent,
    HouseholdAboutComponent,
    HouseholdServicesComponent,
    HouseholdPortfolioComponent,
    TestimonialsComponent,
    ContactusComponent,
    DiysectionComponent,
    HousebuildersectionComponent,
    BuilderAboutComponent,
    BuilderGetintouchComponent,
    BuilderHeaderComponent,
    BuilderContactusComponent,
    BuilderPortfolioComponent,
    FaqBuilderComponent,
    FaqHouseholdComponent,
    FloorSectionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxPageScrollModule,
    LightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
