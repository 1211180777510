import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Email, IEmail} from '../../shared/email.model';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {EmailService} from '../../shared/email.service';
import {HttpResponse} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-builder-contactus',
  templateUrl: './builder-contactus.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./builder-contactus.component.css']
})
export class BuilderContactusComponent implements OnInit {
  private _success = new Subject<string>();
  public message = new Email('', '', '');
  staticAlertClosed = false;
  successMessage: string;
  shownDialog: boolean;
  constructor(private emailService: EmailService, private modalService: NgbModal) { }

  ngOnInit() {
    setTimeout(() => this.staticAlertClosed = true, 20000);

    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }

  public changeSuccessMessage() {
    this._success.next(`Message successfully sent.  We will be in touch shortly.`);
  }

  submitMessage() {
    this.emailService.sendEmail(this.message).subscribe(
      (res: HttpResponse<IEmail>) => {
        this.message.name = '';
        this.message.emailAddress = '';
        this.message.message = '';
        this.changeSuccessMessage();
      }
    );
  }

  openVerticallyCentered(content) {
    if (!this.shownDialog) {
      this.modalService.open(content, {centered: true});
      this.shownDialog = true;
    }
  }
}
