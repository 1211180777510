import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-housebuildersection',
  templateUrl: './housebuildersection.component.html',
  styleUrls: ['./housebuildersection.component.css']
})
export class HousebuildersectionComponent implements OnInit {

  constructor(meta: Meta, title: Title) {
    title.setTitle('Homeowner | MFH Underfloor Heating');
    meta.addTags( [
      { name: 'author', content: 'Midlands Floor Heating' },
      { name: 'description', content: 'Underfloor Heating for your home, efficient solutions designed by experts.' }
    ])
  }

  ngOnInit() {
  }

}
